<template>
  <div class="col-12 col-lg-6 c g">
    <div class="row">
        <div class="col-12 col-lg-6">
            <div class="card card-body item text-center" @click="$router.push('/_results/send')">
                <h3>
                    <img :src="require('@/assets/images/paper-plane.png')" alt="">
                    ارسال الشهادات
                </h3>
            </div>
        </div>
        <div class="col-12 col-lg-6">
            <div class="card card-body item text-center" @click="$router.push('/_results/history')">
                <h3>
                    <img :src="require('@/assets/images/history.png')" alt="">
                    العمليات السابقة
                </h3>
            </div>
        </div>
        <div class="col-12 col-lg-6">
            <div class="card card-body item text-center" @click="$router.push('/_results/stats')">
                <h3>
                    <img :src="require('@/assets/images/pie-chart.png')" alt="">
                    احصائيات الشهادات
                </h3>
            </div>
        </div>
        <div class="col-12 col-lg-6">
            <div class="card card-body item text-center" @click="$router.push('/_results/link')">
                <h3>
                    <img :src="require('@/assets/images/url.png')" alt="">
                    الرابط الموحد
                </h3>
            </div>
        </div>
        <div class="col-12 col-lg-6">
            <div class="card card-body item text-center" @click="$router.push('/archive?type=results')">
                <h3>
                    <img :src="require('@/assets/images/asks.png')" alt="">
                    ارشيف الرسائل
                </h3>
            </div>
        </div>
        <div class="col-12 col-lg-6">
            <div class="card card-body item text-center" @click="$router.push('/add-import')">
                <h3>
                    <img :src="require('@/assets/images/noor.jpg')" alt="">
                    استيراد الطلاب
                </h3>
            </div>
        </div>
        <div class="col-12 col-lg-6 c">
            <div class="card card-body item text-center" @click="$router.push('/extension')">
                <h3>
                    <img :src="require('@/assets/images/chrome.png')" alt="">
                    تثبيت الاداة
                </h3>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    data(){
        return {
            user: JSON.parse(localStorage.getItem('user')),
        }
    },
    created(){
        if(window.location.href.includes("?id=")){
            window.location = '/_results/send?id=' + window.location.href.split("?id=")[1]
        }
    }
}
</script>

<style>
.item{
    border: solid 2px #fff;
}
.item:hover{
    border: solid 2px #ddd;
    cursor: pointer;
    background: #fafafa;
}
.item img{
    width: 64px;
}
</style>